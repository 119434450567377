import React from 'react'
import CtaMeet from '../../components/CtaMeet'
import Layout from "../../components/Layout"
import { Container } from 'react-bootstrap'
import FAQMSHS from '../../components/FAQMSHS'
import MSHSBox from "../../components/MSHSBox";
import SharedServicesPacks from "../../components/SharedServicesPacks";
import BannerMSHS from '../../components/BannerMSHS'
import MSHSBoxUnder from "../../components/MSHSBoxUnder";
import { Seo } from '../../components/Seo'
// import myImg from '../../public/te-og.png'
import CaseStudy from '../../components/CaseStudy'
import MSHSPlatforms from '../../components/MSHSPlatforms'
import { useLocation } from "@reach/router"

export default function() {
    const pathLocation = useLocation();
    const url = pathLocation.href;
    return (
    <Layout>
        {/*  image={'https://sogody.com'+myImg} */}
        <Seo 
            title="Managed & Shared Servcies - Sogody"
            description=" A partnership framework between you and Sogody, where we help you create an agile and innovative digital foundation for driving continuous success."
            url={url}
        />
        <div className="managed-services-page">  
        <BannerMSHS/>
        {/* <MSHSBoxUnder/> */}
        {/* <TeApproach/>
        <WWOTE/> */}
        {/* <MSHSBox/> */}
        <SharedServicesPacks/>
        <Container>
            <CtaMeet/> 
        </Container> 
        <MSHSPlatforms/>
        <CaseStudy/>
        {/* <FAQMSHS/> */}
        </div>
    </Layout>
    )
}


