import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import {Card} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulLanguagesAndPlatforms:{
        nodes:lap
    }} = data

    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 2
    // };
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 2560,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            }
        },
            {
            breakpoint: 1920,
            settings: {
            slidesToShow: 3,
            slidesToScroll:1,
            }
        },
            {
            breakpoint: 1440,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 2,
            slidesToScroll:1,
            initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 1.3,
            slidesToScroll: 1
            }
        }
    ]
};


return ( 
    <div className="lang-platforms"> 
        {/* <Container>  */}
            <Slider {...settings}>
                {lap.map((lap)=>{
                return <Card className="lp-card"> 
                    <Card.Body>
                        <Card.Title>
                            <img  style={{ maxWidth: "100%", maxHeight: "35px" }} src={lap.image.fluid.src} alt="Logo" />
                        </Card.Title>
                        <Card.Text>
                            <p className="card-description">{lap.description.description}</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
                })}
            </Slider>
        {/* </Container> */}
    </div>  
    )
}

export const query = graphql`
{
    allContentfulLanguagesAndPlatforms(sort: {fields: updatedAt},filter: {node_locale: {eq: "en-US"}}) {
        nodes {
        description {
            description
        }
        image {
            fluid(quality: 100) {
            src
            }
        }
        }
    }
}
`


