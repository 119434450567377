import React, { useState }  from "react"
import { Row, Col, Container,Button} from 'react-bootstrap';
import banner from '../assets/images/services/managed-banner.png';
import ContactUs from "./ContactUs";

import '../assets/style.css' ;
export default function() {
    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="banner-mshs single-service-banner">
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={7} className="banner">
                        <h1 className="name">Optimize your digital work with our managed-services</h1>
                        <p className="paragraph">A partnership framework between the client and Sogody in delivering continuous digital experiences for increasing commercial value. Benefit by the cost-efficiency of the tech spent and by continuously growing the digital capacities.</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        > Contact us </Button>
                    </Col>
                    <Col xs={12} md={12} lg={5} className="banner-img"><img src={banner} alt="managed services banner" /></Col>
                </Row>
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>  
        </div>
    )
}



