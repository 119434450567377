import React from "react"
import {  useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Col, Container} from 'react-bootstrap';
import LangPlatforms from "../components/LangPlatforms";

export default function() {

    const platforms = [
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/React.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/JS.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/CSS.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/Html.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/NoIdea.png" },
        {imgUrl: "https://assets.sogody.co.uk/sogody/revamp/Ruby.png" },
        
    ]

    return (
        <div className="platforms">  
            <Container>  
                <div className="content-box">
                    <p className="title">We work in the languages and platforms that you do</p> 
                    <p className="subtitle">The expertise stack used to deliver our shared-services</p>
                    <div className="platforms-col">
                        {platforms.map((platforms, index) => ( 
                            <img  style={{ maxWidth: "100%", maxHeight: "60px" }} src={platforms.imgUrl} alt="Logo" key={index} />
                        ))}
                    </div>
                </div>
                <Row style={{display: "flex",textAlign: "center", overflow: "hidden"}}>
                    <Col>
                        <LangPlatforms/>
                    </Col>
                </Row> 
            </Container>
            </div>
    )
}


