import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {BLOCKS, MARKS} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {Container, Button, Card, Row, Col } from "react-bootstrap";
import ContactUs from './ContactUs';

const ComponentName = () => {
    
    const data = useStaticQuery(query);
    const {allContentfulOurSharedServicesPacks:{
        nodes:shsp
    }}  = data

    const [modalShow, setModalShow] = React.useState(false);
    
    const options={
        renderNode:{

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="sb-image" alt="asset in content" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),

        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="shsp-paragraph">{children}</p>),
        },

        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            [MARKS.BOLD]: text => <b>{`${text}`} </b>,
        },
    }


return <div className="shs-packs">
        <Container> 
            <Row>
                <div className="shsp-head">
                    <h2>Our managed-services packs</h2> 
                    <span>Personalized for digital and marketing teams.</span>
                </div>
            {shsp.map((shsp)=>{
                return(
                    <Col xs="12" md="6" lg="3"> 
                <Card className={shsp.slug}>
                    <Card.Header className="pack-img">
                        <img  style={{ maxWidth: "100%", maxHeight: "100%" }} src={shsp.image.file.url} alt="Logo" />
                    </Card.Header>
                    <Card.Body>
                    <Card.Title> <span className="pack-name">{shsp.packetName} </span><span className="pack-number"> {shsp.title}</span></Card.Title>
                        <Card.Text>
                        {documentToReactComponents(shsp.list.json, options)
                            && (
                                <p className="shsp-box-rich-text">{documentToReactComponents(shsp.list.json, options)}</p>
                            )
                        }
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted"> <Button onClick={() => setModalShow(true)}  className={`primary-btn-s ` + shsp.packetName}> {shsp.btnTitle}
                        </Button></Card.Footer>
                </Card>
                </Col>
                )})}
            </Row>
            <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
</div>
}

export const query = graphql`
{
    allContentfulOurSharedServicesPacks(filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt}) {
        nodes {
        btnTitle
        title
        slug
        packetName
        list {
            json
        }
        image {
            file{
                url
            }
        }
        }
    }
}
`

export default ComponentName

